import { Field, Form, Formik, useFormik } from 'formik'
import React from 'react'

const InputWithRef = React.forwardRef<HTMLInputElement>((props, ref) => (
    <input ref={ref} {...props} />
));

export const WillShell = () => {
    const [log, setLog] = React.useState<string[]>([])
    const inputRef = React.useRef<HTMLInputElement | null>(null)

    return (
        <div className="text-xl h-full text-white font-dos">
            {/* <div className="bg-portfolio-700 text-center">Will Shell (WiSH) v0.0.1</div> */}
            <div className="h-full bg-black" onClick={() => {
                console.log("click!!!")
                inputRef?.current?.focus()}}>
                <Formik
                    initialValues={{ input: '' }}
                    onSubmit={async (values, form) => {
                        const nextLog = [...log]
                        if (nextLog.length > 150) nextLog.shift()
                            /** @TODO This to become command output */
                        nextLog.push(`> ${values.input}`)
                    setLog(nextLog)
                        await form.setFieldValue('input', '')
                    }}
                >
                    {({ values }) => (

                        <Form>
                            <div>{log.map((item, index) => (<p key={`${index}-${item}`}>{item}</p>))}</div>
                            <div className="flex gap-2">
                                <div className="min-w-[30px]">-&gt;</div>
                                <Field
                                    ref={inputRef}
                                    component={InputWithRef}
                                    type="text"
                                    name="input"
                                    className="w-full bg-black outline-none flex wish-input-caret"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}