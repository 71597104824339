import React from "react"

import blooms01 from "../../assets/images/blooms/blooms-01.png"
import blooms02 from "../../assets/images/blooms/blooms-02.png"
import { DisplayImage } from "../DisplayImage"


export const Blooms = () => {
    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Blooms the Chemist website</h1>
            <p className="mt-4">While working at Holler, I collaborated with a senior developer to build a website and a customer portal for Bloom's the Chemist. The website was a WordPress build, and my role was to develop and style its various components. The customer portal was primarily developed by myself using React and interacting with APIs built by another team member.</p>
            <DisplayImage image={blooms01} caption="The landing page for the Blooms customer portal" />
            <p className="mt-4">The purpose of the website was to inform users about Blooms the Chemist as a company, the services they provided, and to provide information about their products and prices. The customer portal allowed the user to log into their account and view the results of their health checkups and the progress of various health programs they were engaged in through Blooms.</p>
            <DisplayImage image={blooms02} caption="Specials on offer at Blooms as seen through the customer portal" />
        </div>
    )
}

