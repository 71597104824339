import React from "react"
// @ts-ignore
import aldiVideo from "../../assets/videos/Aldi-Catalog.mp4"

export const MagazineAR = () => {
    return (
        <div className="@container max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Magazine AR</h1>
            <div className="flex gap-6 flex-col @3xl:flex-row">
                <p className="mt-4">While working at Ovato, my team's goal was to complement the company's printing capabilities with innovative digital products. Augmented reality was the perfect tool to use with printed material, and we brought our client's magazine covers to life. Two notable projects that I independently developed included Aldi's winter promotional catalogue for 2021 and Harper's Bazaar magazine. Utilising the 8thWall framework, I used each client's magazine cover as an image target that the 8thWall engine could scan for via the user's camera. Once the 8thWall engine detected the cover, it used it as a real-world anchor and applied our 3D content to the application. As a result, the image on the cover became animated, and a 3D button appeared that led users to the promotion.</p>
                <video controls className="h-[680px] block border-2 border-black bg-gray-800" src={aldiVideo} />
            </div>
        </div>
    )
}