import React from 'react'

interface Props {
    title: string;
    dateRange: string;
    description: string | string[];
}

export const ExperienceItem = ({ title, dateRange, description }: Props) => (
    <div className="relative pl-4 @3xl:pl-8 pb-8 border-l-4 border-purple-200 last-of-type:pb-2 before:content-[''] before:w-6 before:h-6 before:inline-block before:border-4 before:border-purple-200 before:rounded-full before:bg-white before:absolute before:-left-3.5">
        <div className='flex flex-col @3xl:flex-row items-baseline gap-4 pb-2 text-lg @3xl:text-xl border-b-4 border-b-gray-200'>

            <h4 className="">{title}</h4>
            <span className="hidden @3xl:inline-block">&#8226;</span>
            <p className='font-light italic'>{dateRange}</p>
        </div>
        {(description instanceof Array) ? (
            <ul className='list-disc text-base @3xl:text-lg mt-2 ml-2 @3xl:ml-4'>
                {description.map((item, i) => (
                    <li key={i}>{item}</li>
                ))}
            </ul>

        ) : (
            <p className="mt-2">{description}</p>
        )}
    </div>

)