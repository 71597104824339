import * as React from "react";

interface DraggerProps {
    fullWidth?:boolean;
    children: React.ReactNode;
    onDrag: (e:MouseEvent) => void;
}



export default function Dragger({ fullWidth, children, onDrag }:DraggerProps) {
    const [mouseDown, setMouseDown] = React.useState(false);

    React.useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchstart', handleMouseUp);
        return () => { window.removeEventListener('mouseup', handleMouseUp) };
    }, []);

    React.useEffect(() => {
        const handleMouseMove = (e: MouseEvent | TouchEvent) => onDrag(e as MouseEvent)
        if(mouseDown) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('touchmove', handleMouseMove);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleMouseMove);
        }
    }, [mouseDown, onDrag])

    const handleMouseDown = () => setMouseDown(true)


    return (
        <div style={{ width: fullWidth ? "100%" : "" }} onMouseDown={handleMouseDown}>{children}</div>
    )
}