import React from 'react'
import atlas01 from "../../assets/images/atlas/Atlas-01.png"
import atlas02 from "../../assets/images/atlas/Atlas-02.png"
import { DisplayImage } from '../DisplayImage'

export const Atlas = () => {

    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Ovato Atlas</h1>
            <p className="mt-4">Atlas was a geospatial data visualising application used by Ovato. It allowed users to target areas within Australia based on a set of parameters to help the distribution of printed materials such as catalogues or promotional pamphlets to areas that would yield a high return. I was in charge of developing the frontend client in its entirety and integrating the APIs that had been built by another team member. Atlas was my main focus for approximately two years at the company.</p>
            <DisplayImage image={atlas01} caption="The Atlas dashboard with one campaign active and several filters in use" />
            <p className="mt-4">Atlas could be used to view various store locations across the nation, create properties that could be applied to GeoJSON data, filter the GeoJSON by selected properties, visualise the properties using colour or extrusion on the GeoJSON layer, target the maximum audience for the current campaign within a limit, apply clustering to the property data to view similarities across the campaign, and much more.</p>
            <DisplayImage image={atlas02} caption="Extrusion and colour being used to represent data values" />
        </div>
    )
}