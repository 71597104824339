import React from "react"
import { Loader } from "./Loader"

interface Props {
    image: string
    caption: string
}

export const DisplayImage = ({ image, caption }: Props) => {
    const [isLoading, setIsLoading] = React.useState(true)    

    return (
        <figure className="max-w-[800px] min-h-96 mx-auto mt-4 bg-gray-800 border-2 border-black text-center text-sm shadow-brutal">
            {isLoading && (
                <div className="h-96 flex flex-col items-center justify-center">
                    <Loader />
                </div>
            )}
            <img className="block max-h-96 w-auto mx-auto" src={image} alt={caption} onLoad={() => setIsLoading(false)} />
            <figcaption className="py-1 bg-portfolio-200">{caption}</figcaption>
        </figure>

    )
}