import React from "react"
import Typed from "typed.js"

import estar from "../assets/images/es-boot.png"
import { ScreenSize, getScreenSize } from "../utils/getScreenSize"


export const Boot = () => {
    const [isRendered, setIsRendered] = React.useState(false)
    const bootRef = React.useRef<HTMLDivElement>(null)
    const consoleRef = React.useRef<HTMLDivElement>(null)
    const screenSize = getScreenSize()

    const breakSpace = screenSize === ScreenSize.MOBILE ? '<br />' : '<br /><br /><br />' 

    React.useEffect(() => {
        setTimeout(() => setIsRendered(true), 750)
    }, [])

    React.useEffect(() => {
        if (!consoleRef.current || !bootRef.current) return
        const typed = new Typed(consoleRef.current, {
            strings: [
                'Resolving DNS^250\n `.` ^250\n `.` ^250\n `. . . [DONE]` <br />' +
                'Handshaking^250\n `.` ^250\n `. . . [DONE]` <br />' +
                'Loading page from server^250\n `.` ^250\n `.` ^250\n `.` ^250\n `. [DONE]` <br />' +
                'Preparing render^250\n `.` ^250\n `. . [DONE]` <br />' +
                breakSpace +
                'Load is complete . . .<br />' +
                'Initialising system <br />' +
                'Welcome to the Will Dunlop website experience. (c) 2023-2024 <br />' +
                'We hope you enjoy your stay'
            ],
            cursorChar: '█',
            typeSpeed: 10,
            startDelay: 500,
            onComplete: () => {
                bootRef?.current?.classList.add('doom-scroll')
                setTimeout(() => {
                    bootRef.current?.classList.add('hidden')
                }, 1950)
            }
        });

        return () => typed.destroy();
    }, [isRendered, breakSpace])

    return (
        <div ref={bootRef} className="min-h-screen w-screen absolute  bottom-0 z-50 bg-black text-portfolio-200 font-dos">
            {isRendered && (
                <>
                    <div className="flex items-start justify-between">
                        <div className=" flex flex-col md:flex-row">
                            <pre className="text-[10px] lg:text-base text-red-400">
                                ██╗    ██╗██████╗<br />
                                ██║    ██║██╔══██╗<br />
                                ██║ █╗ ██║██║  ██║<br />
                                ██║███╗██║██║  ██║<br />
                                ╚███╔███╔╝██████╔╝<br />
                                ╚══╝╚══╝ ╚═════╝<br />

                            </pre>
                            <div className="text-lg lg:text-xl ml-0 md:ml-6 lg:ml-12 mt-4 md:mt-0 flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-6 xl:gap-10">
                                <p>William Dunlop Inc.</p>
                                <div>
                                    <p>Released: 11/12/23</p>
                                    <p>WDBIOS (c) 2023 William Dunlop Inc.</p>
                                </div>
                            </div>
                        </div>
                        <img src={estar} alt="energy-star-rating" className="w-auto h-24 lg:h-36 mr-8" />
                    </div>
                    <div className="text-lg lg:text-xl mt-4">
                        <p>Detected system : {window.navigator.userAgent}</p>
                        <div ref={consoleRef} className="mt-2"></div>
                    </div>
                </>
            )}
        </div>
    )
}