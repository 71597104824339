import React from 'react'

import ocean01 from "../../assets/images/blackmores/Dashboard.png"
import ocean02 from "../../assets/images/blackmores/Turbulent.png"
import { DisplayImage } from '../DisplayImage'

export const Blackmores = () => {
    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Blackmores Mindful Ocean VR</h1>
            <p className="mt-4">I independently developed the second iteration of the Mindful Ocean WebVR experience for Blackmores. This application interfaced with a Muse headband that monitored the wearer's EEG readings to detect changes in their brainwaves. A calm mind produced a tranquil ocean, whereas an active mind created a violent and turbulent ocean. The experience walked the user through the process of achieving both states of mind and provided a score based on their performance.</p>
            <DisplayImage image={ocean01} caption="The dashboard used by Blackmores employees to monitor the experience" />
            <p className="mt-4">Mindful Ocean VR was a full-stack project that involved a socket server to interface with the Muse headband,  a Three.js WebVR frontend, and an admin dashboard that allowed Blackmore's employees to monitor and control the experience from a laptop or tablet.</p>
            <DisplayImage image={ocean02} caption="The user has an active mind and the ocean becomes turbulent" />
        </div>
    )
}

