import * as React from "react";

interface ResizeProps {
    onResize: (d:string, e:MouseEvent) => void;
}

export enum Direction {
    TOP = "TOP",
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT",
    RIGHT = "RIGHT",
    BOTTOM = "BOTTOM",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    LEFT = "LEFT"
}


export default function Resizer({ onResize }:ResizeProps) {
    const [direction, setDirection] = React.useState("");
    const [mouseDown, setMouseDown] = React.useState(false);

    React.useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, []);

    React.useEffect(() => {
        const handleMouseMove = (e:MouseEvent | TouchEvent) => {
            if (!direction) return;
            onResize(direction, e as MouseEvent)
            // resizer.onResize(props.element, direction, e)
        };
        if (mouseDown) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('touchmove', handleMouseMove);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleMouseMove);
        };
    }, [mouseDown, direction, onResize])

    const handleMouseDown = (direction:string) => {
        setDirection(direction);
        setMouseDown(true)
    }

    return(
        <>
            <div className="absolute cursor-nwse-resize h-3 w-3 z-[26] left-0 top-0 -translate-x-3 -translate-y-3" onMouseDown={() => handleMouseDown(Direction.TOP_LEFT)}></div>
            <div className="absolute cursor-ns-resize h-3 w-full z-[25] left-0 top-0 -translate-y-3" onMouseDown={() => handleMouseDown(Direction.TOP)}></div>
            <div className="absolute cursor-nesw-resize h-3 w-3 z-[26] right-0 top-0 translate-x-3 -translate-y-3" onMouseDown={() => handleMouseDown(Direction.TOP_RIGHT)}></div>
            <div className="absolute cursor-ew-resize h-full w-3 z-[25] right-0 top-0 translate-x-3" onMouseDown={() => handleMouseDown(Direction.RIGHT)}></div>
            <div className="absolute cursor-nwse-resize h-3 w-3 z-[26] right-0 bottom-0 translate-x-3 translate-y-3" onMouseDown={() => handleMouseDown(Direction.BOTTOM_RIGHT)}></div>
            <div className="absolute cursor-ns-resize h-3 w-full z-[25] left-0 bottom-0 translate-y-3" onMouseDown={() => handleMouseDown(Direction.BOTTOM)}></div>
            <div className="absolute cursor-nesw-resize h-3 w-3 z-[26] left-0 bottom-0 -translate-x-3 translate-y-3" onMouseDown={() => handleMouseDown(Direction.BOTTOM_LEFT)}></div>
            <div className="absolute cursor-ew-resize h-full w-3 z-[25] left-0 top-0 -translate-x-3" onMouseDown={() => handleMouseDown(Direction.LEFT)}></div>
        </>
    )
}