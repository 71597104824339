import React from 'react'

export const skills = [
    "HTML",
    "CSS/SCSS",
    "JavaScript/Typescript",
    "Node.js",
    "React",
    "Tailwind.css",
    "WebGL/Three.js",
    "WebAR/VR",
    "Express.js",
    "Next.js",
    "MongoDB",
    "PostgreSQL",
    "Jest",
    "Playwright",
    "Svelte",
    "Python",
    "Godot",
    "Blender"
]


export const Welcome = () => {

    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Welcome!</h1>
            <p className="mt-4"> My name is Will Dunlop, and I am a full-stack software engineer based in Sydney, Australia.</p>
            <p className="mt-4">Coding is a passion of mine that comes from a life long fascination with technology. Programming is a rewarding and challenging experience that enables me to take full advantage of modern-day computing. My strengths lie on the front end, where I can use my knowledge and understanding of technology as a form of artistic expression. Whether it is something as mundane as implementing a web-form or something more exciting involving 3D and mathematics, the ability to fine-tune the user's experience is something I find particularly satisfying.</p>
            <p className="mt-4">Click <span className="px-4 py-2 bg-gray-200 border-2 border-black">Start</span> in the bottom left hand corner to learn more about my coding experience.</p>

            <h2 className="text-center text-2xl my-4 border-b border-black">Skills</h2>
            <ul className="mx-auto text-sm flex gap-4 flex-wrap max-w-[620px]">
                {skills.map((skill) => (
                    <li key={skill}>
                        <span className="border-2 border-black bg-purple-200 font-semibold rounded-full p-2">{skill}</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}
