import { Applet, FloatingPosition } from "../components/Desktop";
import { Atlas } from "../components/applets/Atlas";

import hhIcon from "../assets/icons/hh.png"
import prochoIcon from "../assets/icons/procho.png"
import ovatoIcon from "../assets/icons/ovato.png"
import magazineIcon from "../assets/icons/8thWall-logo.jpg"
import blackmoresIcon from "../assets/icons/blackmores.png"
import backroomsIcon from "../assets/icons/backrooms.png"
import demmoIcon from "../assets/icons/demmo.png"
import { MagazineAR } from "../components/applets/MagazineAR";
import { Blackmores } from "../components/applets/Blackmores";
import { OvatoWheel } from "../components/applets/OvatoWheel";
import { Blooms } from "../components/applets/Blooms";
import { ProCho } from "../components/applets/ProCho";
import { HeyHubble } from "../components/applets/HeyHubble";
import { ScreenSize, getScreenSize } from "../utils/getScreenSize";
import { DeMMO } from "../components/applets/Demmo";
import { BackroomsZero } from "../components/applets/BackroomsZero";

const initialFloatingPosition: FloatingPosition = {
    width: "160px",
    height: "160px",
    top: "10",
    left: "10",
}

function initApplet(applet: Applet, index: number): Applet {
    const screen = getScreenSize()
    switch (screen) {
        case ScreenSize.MOBILE: 
            return {
                ...applet,
                fullscreen: false,
                floatingPosition: {
                    ...initialFloatingPosition,
                    width: "95%",
                    height: "90%",
                    top: `${0 + index * 8}px`,
                    left: `${0 + index * 4}px`
                }
            }
        case ScreenSize.TABLET:
        case ScreenSize.LAPTOP:
                return {
                    ...applet,
                    floatingPosition: {
                        ...initialFloatingPosition,
                        width: "80%",
                        height: "70%",
                        top: `${0 + (index+1) * 32}px`,
                        left: `${0 + (index+2.5) * 16}px`
    
                    }
                }
        case ScreenSize.DESKTOP:
            return {
                ...applet,
                floatingPosition: {
                    ...initialFloatingPosition,
                    width: "1080px",
                    height: "720px",
                    top: `${0 + (index+1) * 32}px`,
                    left: `${0 + (index+2.5) * 16}px`

                }
            }
        case ScreenSize.TV:
            return {
                ...applet,
                floatingPosition: {
                    ...initialFloatingPosition,
                    width: "1080px",
                    height: "720px",
                    top: `${0 + (index+1) * 64}px`,
                    left: `${0 + (index+2.5) * 32}px`

                }
            }
        default: return applet
    }

}


export const initialApplets: Applet[] = [

    {
        id: "DEMMO",
        title: "DeMMO",
        icon: demmoIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "35%",
            left: "17.5%",
        },
        content: () => DeMMO({ skipInfo: false })
        
    },
    {
        id: "BACKROOMS_ZERO",
        title: "Backrooms Zero",
        icon: backroomsIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "35%",
            left: "17.5%",
        },
        content: () => BackroomsZero({ skipInfo: false })
        
    },
    
]

export const hobbies = initialApplets.map(initApplet)

