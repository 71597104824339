import * as React from 'react'
import Dragger from './Dragger';
import { Applet } from './Desktop';
import classNames from 'classnames';

interface Props {
    applet: Applet
    className?: string
    onOpenWindow: (applet: Applet) => void
    onDrag: (element: HTMLElement, e: MouseEvent, applet: Applet) => void
}

export default function Shortcut({ applet, className, onOpenWindow, onDrag }: Props) {
    const shortcutRef = React.useRef(null)
    const clickTimer = React.useRef<NodeJS.Timeout>()

    const onClickHandler = (event: any) => {
        clearTimeout(clickTimer.current);
        if (event.detail === 1) {
            clickTimer.current = setTimeout(() => {
                /** This is the single click handler */
            }, 200)
        } else if (event.detail === 2) {
            onOpenWindow(applet)
        }
    }

    return (
        <Dragger fullWidth={true} onDrag={(e) => onDrag(shortcutRef.current!, e, applet)}>
            <div ref={shortcutRef} className={classNames(className, "group absolute z-10 cursor-pointer outline-none")} tabIndex={1} onClick={onClickHandler}>
                <img className="h-9 w-9 mx-auto select-none" draggable={false} src={applet.icon} alt={`${applet.title} shortcut`} />
                <div
                    className={classNames(
                        "text-center overflow-hidden px-1 mt-1  text-ellipsis select-none",
                        "focus:bg-portfolio-200 focus:border focus:border-dotted focus:border-portfolio-900",
                        "group-focus:bg-portfolio-200 group-focus:border group-focus:border-dotted group-focus:border-portfolio-900"
                        )}
                    tabIndex={0}
                >
                    {applet.title}
                </div>
            </div>
        </Dragger>
    )
}