import React from "react"
import { UAParser } from "ua-parser-js"
import { DisplayImage } from "../DisplayImage";

import br01 from "../../assets/images/backrooms/backrooms-01.png"
import br02 from "../../assets/images/backrooms/backrooms-02.png"
import br03 from "../../assets/images/backrooms/backrooms-03.png"
import br04 from "../../assets/images/backrooms/backrooms-04.png"
import br05 from "../../assets/images/backrooms/backrooms-05.png"
import br06 from "../../assets/images/backrooms/backrooms-06.png"
import { getScreenSize, isMobileDevice, ScreenSize } from "../../utils/getScreenSize";

interface DeMMOProps {
    skipInfo?: boolean;
}

const backroomsUrl = "https://backrooms.willdunlop.com/game"


export const BackroomsZero = ({ skipInfo = false }: DeMMOProps) => {
    const [isPlaying, setIsPlaying] = React.useState(skipInfo)
    const screenSize = getScreenSize()
    const canPlay = (screenSize !== ScreenSize.MOBILE && screenSize !== ScreenSize.TABLET) && !isMobileDevice()

    if (isPlaying) {
        return (
            <iframe
                id="inlineBackrooms"
                title="Backrooms"
                width="100%"
                height="100%"
                src={backroomsUrl}>
            </iframe>
        )
    }

    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto pb-6">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Backrooms Zero</h1>
            <p className="mt-4">In 2019, a post appeared online that described a fictional space known simply as "The Backrooms". The post suggests the possibility that you may find yourself going about your daily routine, until you accidentally slip (no-clip) through a particularly unstable patch of reality. If this happens, you may find that you wake up in the Backrooms, a labyrinth of continuous and interconnected sections of liminal spaces. The horror based concept sparked a lot of interest which has been expanded upon by online short films, video games, and even an upcoming feature film.</p>
            <DisplayImage image={br04} caption="The narrow and claustrophobic hallways of The Backrooms" />
            <p className="mt-4">As someone who found himself drawn in by the mysterious nature of this ill-defined space in reality, I decided to try and use the theme as the basis for a small game. Backrooms Zero uses Three.js to construct randomly generated levels that the user has to try and escape from. The goal is very simple, find the elevator in the hopes that it will lead you out of this terrible place. Just be warned, it might also take you further in.</p>
            <DisplayImage image={br05} caption="A large and open space found within the depths of The Backrooms" />
            <p className="mt-4">The level generation of this game is based on a grid system. A grid is generated where each cell will either be considered as an open space or a closed space. An open space will have a segment generated for it, if it borders with a closed cell, then that segment will render a wall to prevent the user from being able to access that part of the grid. {/**<a className="underline text-blue-900" href="https://www.freecodecamp.org/news/how-to-make-your-own-procedural-dungeon-map-generator-using-the-random-walk-algorithm-e0085c8aa9a/">This guide</a> by Ahmad Abdolsaheb was used as the basis for the algorithm behind the grid generation. */}</p>
            <DisplayImage image={br06} caption="Left: A single segment, represents the space of a single cell. Right: An entire level constructed with a series of generated segments" />
            {
                canPlay
                    ? (
                        <>
                            <p className="mt-4">If you are interested in trying out Backrooms Zero, click the 'Play Now' button below. At this time however, the game does not work on mobile, a PC or laptop will be required to play it. It should also be noted that this project is very much a work in progress and may contain bugs and performance issues</p>
                            <div className="w-full text-center">
                                <button onClick={() => setIsPlaying(true)} className="px-4 py-2 mt-6 bg-gray-200 border-2 border-black hover:bg-gray-400 active:bg-gray-600 active:text-white">Play Now</button>
                            </div>
                        </>
                    ) : (
                        <p className="mt-4">In order to play Backrooms Zero, you will need to come back here on a laptop or PC. Backrooms Zero is not available for mobile devices at this time</p>
                    )
            }
            {/* <p className="mt-4">Backrooms is not yet live, there are plans however to host it and make it available here.</p> */}
        </div>
    )
}
