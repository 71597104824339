import classNames from "classnames"
import React from "react"
import { Applet } from "./Desktop"
import { applets } from "../data"
import submenuIcon from "../assets/icons/submenu.png"
import documentsIcon from "../assets/icons/documents.png"
import { professional } from "../data/professional"
import { hobbies } from "../data/hobbies"

interface MenuItemData {
    name: string;
    menuIcon: string;
    content: Applet | MenuItemData[]
}

interface MenuItemProps {
    className?: string
    itemData: MenuItemData
    children: string
    icon: string
    isLargeItem?: boolean
    subMenuItems?: MenuItemData[]
    onClick: (applet: Applet) => void
}

const menuItemData: MenuItemData[] = [
    // {
    //     name: "Programs",
    //     menuIcon: submenuIcon,
    //     content: [
    //         {
    //             name: 'Games',
    //             menuIcon: submenuIcon,
    //             content: [
    //                 {
    //                     name: "DeMMO",
    //                     menuIcon: applets.find((applet) => applet.id === "DEMMO")!.icon,
    //                     content: applets.find((applet) => applet.id === "DEMMO")!
    //                 }
    //             ]
    //         }],
    // },
    {
        name: "Work",
        menuIcon: documentsIcon,
        content: professional.map((applet) => ({ name: applet.title, menuIcon: applet.icon, content: applet })),
    }, {
        name: "Hobbies",
        menuIcon: documentsIcon,
        content: hobbies.map((applet) => ({ name: applet.title, menuIcon: applet.icon, content: applet })),
        // content: [{
        //     name: "DeMMO",
        //     menuIcon: applets.find((applet) => applet.id === "DEMMO")!.icon,
        //     content: applets.find((applet) => applet.id === "DEMMO")!,
        // }],
    }, {
        name: "About Me",
        menuIcon: applets.find((applet) => applet.id === "ABOUT_ME")!.icon,
        content: applets.find((applet) => applet.id === "ABOUT_ME")!
    }, {
        name: "Welcome",
        menuIcon: applets.find((applet) => applet.id === "WELCOME")!.icon,
        content: applets.find((applet) => applet.id === "WELCOME")!
    },
]

const MenuItem = ({ className, children, itemData, icon, isLargeItem = false, onClick }: MenuItemProps) => {
    const ref = React.useRef<HTMLLIElement>(null)
    const [subMenuOffset, setSubMenuOffset] = React.useState(0)
    const [subMenuIsOpen, setSubMenuIsOpen] = React.useState(false)
    const subMenuItems = Array.isArray(itemData.content) && itemData.content
    React.useEffect(() => {
        if (ref.current && !subMenuOffset) setSubMenuOffset(ref.current.clientWidth)
    }, [ref.current]);

    const handleClick = () => {
        if (!Array.isArray(itemData.content)) onClick(itemData.content)
        else setSubMenuIsOpen(!subMenuIsOpen)
    }

    return (
        <>
            <li
                ref={ref}
                className={className + " outline-none relative"}
                onMouseLeave={() => setSubMenuIsOpen(false)}
                onBlur={() => setSubMenuIsOpen(false)}
            >
                <button
                    aria-label={`start menu item for ${children}`}
                    className={classNames(
                        "w-full flex items-center hover:bg-slate-200 active:bg-portfolio-200",
                        {
                            "pl-4 pr-1 py-2": isLargeItem,
                            "p-1": !isLargeItem,
                            "bg-slate-200": subMenuIsOpen
                        }
                    )}
                    onMouseDown={handleClick}
                    // onClick={onClick}
                    onMouseEnter={() => setSubMenuIsOpen(true)}
                >
                    <img
                        className={classNames("mr-2", {
                            'h-6': isLargeItem,
                            'h-3': !isLargeItem
                        })}
                        src={icon}
                        alt={`Icon for the start menu item ${children}`}
                    />
                    <h2 className="text-ellipsis whitespace-nowrap overflow-x-hidden p-0 mr-4">{children}</h2>
                    {subMenuItems && <div className="ml-auto text-sm">🞂</div>}
                </button>
                {
                    subMenuItems && subMenuItems.length > 0 && subMenuIsOpen && <SubMenu offset={subMenuOffset} isOpen={subMenuIsOpen} menuItems={subMenuItems} onMenuItemClick={onClick} />
                }
            </li>
        </>

    )
}


const SubMenu = ({ offset, menuItems, onMenuItemClick }: MenuProps) => {
    const menuRef = React.useRef<HTMLUListElement | null>(null)
    const offsetStyle = React.useMemo(() => {
        return offset ? { transform: `translate(${offset}px, -2px)` } : {}
    }, [offset])

    React.useEffect(() => {
        if (menuRef.current) {
            const rect = menuRef.current.getBoundingClientRect();
            if (rect.right > window.innerWidth + (rect.width / 2)) {
                menuRef.current.style.transform = `translateX(-${rect.width}px)`
            }
          }
    }, [])

    return (
        <ul ref={menuRef} style={offsetStyle} className={`list-none pl-0 border-2 border-black bg-white bottom-0 ${offset && 'absolute'}`}>
            {menuItems.map((itemData) => {
                // if (!Array.isArray(itemData.content)) {
                //     return (
                //         <MenuItem
                //             key={itemData.name}
                //             icon={itemData.menuIcon}
                //             isLargeItem={false}
                //             className="w-auto sm:w-auto"
                //             itemData={itemData}
                //             onClick={onMenuItemClick}
                //         >
                //             {itemData.name}
                //         </MenuItem>
                //     )
                // } else {
                    return (
                        <MenuItem
                            key={itemData.name}
                            icon={itemData.menuIcon}
                            isLargeItem={false}
                            className="w-full sm:w-auto last:border-b-2"
                            itemData={itemData}
                            onClick={onMenuItemClick}
                        >
                            {itemData.name}
                        </MenuItem>
                    )
                // }

            })}
        </ul>
    )
}

interface MenuProps {
    offset?: number
    isLarge?: boolean
    menuItems: MenuItemData[]
    isOpen: boolean
    onMenuItemClick: (applet: Applet) => void
}
const Menu = ({ isOpen, menuItems, onMenuItemClick }: MenuProps) => {
    return (
        <div
            className={classNames(
                "absolute left-0 bottom-[34px] z-30 flex",
                { "hidden": !isOpen }
            )}
        >
            <div className="w-8 flex flex-col justify-end bg-gradient-to-t from-portfolio-800 to-portfolio-400 border-black border-t-2 border-b-2">
                <div className="-rotate-90 font-ab font-bold text-white text-lg mb-2">WillDunlop93</div>
            </div>
            <div className="relative">
                {/* <h2 className="bg-slate-500 border-t-2 border-r-2 border-l-2 border-black text-white text-center">Menu</h2> */}
                <ul className={`list-none pl-0 border-2 border-black bg-white top-0`}>
                    {menuItems.map((itemData) => {
                        return (
                            <MenuItem
                                key={itemData.name}
                                itemData={itemData}
                                icon={itemData.menuIcon}
                                isLargeItem={true}
                                className="w-full sm:w-auto last:border-b-2"
                                // @ts-ignore
                                onClick={onMenuItemClick}
                            >
                                {itemData.name}
                            </MenuItem>
                        )

                    })

                    }

                </ul>

            </div>
        </div>
    )
}

interface StartBarItemProps {
    children: string
    icon: string
    onClick: () => void
}

const StartBarItem = ({ children, icon, onClick }: StartBarItemProps) => {

    return (
        <>
            <li
                className={"border-r-2 border-black w-[60px] sm:w-[120px] md:w-[180px] lg:min-w-[260px] outline-none relative"}
            >
                <button
                    aria-label={`start menu item for ${children}`}
                    className="p-1 w-full flex items-center hover:bg-slate-200 active:bg-portfolio-200"
                    onClick={onClick}
                >
                    <img
                        className="h-6 mr-2"
                        src={icon}
                        alt={`Icon for the start menu item ${children}`}
                    />
                    <h2 className="text-ellipsis whitespace-nowrap overflow-x-hidden p-0 mr-4">{children}</h2>
                </button>
            </li>
        </>

    )

}

interface StartBarProps {
    openApplets: Applet[]
    onOpenWindow: (applet: Applet) => void
    onWindowMinimised: (applet: Applet) => void
}
export const StartBar = ({ onOpenWindow, onWindowMinimised, openApplets }: StartBarProps) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)

    return (
        <div ref={ref} className="flex absolute z-30 bottom-0 w-full border-t-2 border-black bg-white" tabIndex={0} onBlur={(e) => {
            if (ref.current?.contains(e.relatedTarget)) return
            setIsMenuOpen(false)
        }}>
            <Menu isOpen={isMenuOpen} menuItems={menuItemData} onMenuItemClick={onOpenWindow} />
            <div className="flex pl-0 overflow-hidden">
                <button
                    aria-label="Start bar menu toggle"
                    className="px-4 py-1 border-r-2 border-black hover:bg-slate-200 active:bg-portfolio-200"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >Start</button>
                <ul className="flex list-none pl-0 overflow-x-auto">
                    {openApplets.map((applet) => (
                        <StartBarItem key={applet.id} icon={applet.icon} onClick={() => onWindowMinimised(applet)}>{applet.title}</StartBarItem>
                    ))}

                </ul>
            </div>
        </div>

    )
}