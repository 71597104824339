import React from 'react'
import { StartBar } from './StartBar'
import { Window } from './Window'
import { Direction } from './Resizer';
import { applets } from '../data';
import Shortcut from './Shortcut';

import 'animate.css'
import { Boot } from './Boot';
import { Gradient } from '../lib/gradient';

export interface FloatingPosition {
    width: string;
    height: string;
    top: string;
    left: string;
}

export interface Applet {
    id: string;
    title: string;
    // menuType: string;
    icon: string;
    minimised: boolean;
    fullscreen: boolean;
    floatingPosition: FloatingPosition;
    // style: {
    //     tray?: any;
    //     desktop: AppletStyle;
    //     mobile: AppletStyle;
    // }
    content: (props: unknown) => React.ReactNode
}

export const Desktop = () => {
    const [openApplets, setOpenApplets] = React.useState<Applet[]>([applets.at(-1)!])

    const updateApplet = (applet: Applet) => {
        const openAppletIndex = openApplets.findIndex(openApplet => openApplet.id === applet.id);
        if (openAppletIndex > -1) {
            const newOpenApplets = [...openApplets]
            newOpenApplets.splice(openAppletIndex, 1, applet);
            setOpenApplets(newOpenApplets)
        }
    }

    const onOpenWindow = (applet: Applet) => {
        const openAppletIndex = openApplets.findIndex(openApplet => openApplet.id === applet.id);
        if (openAppletIndex === -1) {
            setOpenApplets([...openApplets, applet])
        } else {
            document.getElementById(`window-${applet.id}`)?.focus()
        }
    }

    const onCloseWindow = (applet: Applet) => {
        const openAppletIndex = openApplets.findIndex(openApplet => openApplet.id === applet.id);
        if (openAppletIndex > -1) {
            const newOpenApplets = [...openApplets]
            newOpenApplets.splice(openAppletIndex, 1);
            setOpenApplets(newOpenApplets)
        }
    }

    const onWindowMinimised = (applet: Applet) => {
        const openAppletIndex = openApplets.findIndex(openApplet => openApplet.id === applet.id);
        console.log("min successfully called", openAppletIndex)
        if (openAppletIndex > -1) {
            const newOpenApplets = [...openApplets]
            newOpenApplets[openAppletIndex].minimised = !newOpenApplets[openAppletIndex].minimised
            setOpenApplets(newOpenApplets)
        }

    }

    function onResize(element:HTMLElement, direction: string, e: MouseEvent, applet: Applet) {
        if (!element) return;
        const { width, height, x, y } = element.getBoundingClientRect() as DOMRect;
        const floatingPosition = { ...applet.floatingPosition }
        const resizeTop = () => {
            floatingPosition.height = `${height - e.movementY}px`;
            floatingPosition.top = `${y + e.movementY}px`;
        };
        const resizeRight = () => { floatingPosition.width = `${width + e.movementX}px` }
        const resizeBottom = () => { floatingPosition.height = `${height + e.movementY}px` }
        const resizeLeft = () => {
            floatingPosition.width = `${width - e.movementX}px`;
            floatingPosition.left = `${x + e.movementX}px`;
        }
        switch (direction) {
            case Direction.TOP_LEFT:
                resizeTop();
                resizeLeft();
                break;

            case Direction.TOP:
                resizeTop();
                break;
            case Direction.TOP_RIGHT:
                resizeTop();
                resizeRight();
                break;
            case Direction.RIGHT:
                resizeRight();
                break;
            case Direction.BOTTOM_RIGHT:
                resizeBottom();
                resizeRight();
                break;
            case Direction.BOTTOM:
                resizeBottom();
                break;
            case Direction.BOTTOM_LEFT:
                resizeBottom();
                resizeLeft();
                break;
            case Direction.LEFT:
                resizeLeft();
                break;
            default:
                break;
        }

        element.style.height = floatingPosition.height
        element.style.width = floatingPosition.width
        element.style.left = floatingPosition.left
        element.style.top = floatingPosition.top

        const updatedApplet = {...applet}
        updatedApplet.fullscreen = false
        updatedApplet.floatingPosition = floatingPosition
        updateApplet(updatedApplet)
    }

    function onDrag(element: HTMLElement, e: MouseEvent, applet: Applet) {
        if (!element) return;
        const updatedApplet = { ...applet, floatingPosition: { ...applet.floatingPosition } }
        const { x, y } = element.getBoundingClientRect() as DOMRect;
        const elementHalfWidth = (element.offsetWidth / 2);
        const elementHalfHeight = (element.offsetHeight / 2);

        let calculatedLeftPos = x + e.movementX;
        let calculatedTopPos = y + e.movementY;
        const topLimit = window.outerHeight - elementHalfHeight;
        const leftLimit = window.outerWidth - elementHalfWidth;
        if (calculatedLeftPos <= leftLimit && calculatedLeftPos >= -elementHalfWidth) {
            element.style.left = calculatedLeftPos + "px";
            updatedApplet.floatingPosition.left = calculatedLeftPos.toString() + "px"
        }
        if (calculatedTopPos <= topLimit && calculatedTopPos >= -elementHalfHeight) {
            element.style.top = calculatedTopPos + "px";
            updatedApplet.floatingPosition.top = calculatedTopPos.toString() + "px"
        }
        updateApplet(updatedApplet)
    }


    function toggleMaximiseWindow(e:React.MouseEvent<HTMLButtonElement, MouseEvent>, element: HTMLElement, applet: Applet) {
        e.preventDefault();
        const updatedApplet = { ...applet }
        if(applet.fullscreen) {
            element.style.width = applet.floatingPosition.width;
            element.style.height = applet.floatingPosition.height;
            element.style.top = applet.floatingPosition.top;
            element.style.left = applet.floatingPosition.left;
            updatedApplet.fullscreen = false
        } else {
            const originalWindowSize = {
                width: element.style.width,
                height: element.style.height,
                top: element.style.top,
                left: element.style.left,
                transform: element.style.transform
            };

            element.style.top = "0";
            element.style.left = "0";
            element.style.transform = 'none';
            element.style.width = '100%';
            element.style.height = 'calc(100% - 32px)';
            
            updatedApplet.floatingPosition = originalWindowSize
            updatedApplet.fullscreen = true
        }
        updateApplet(updatedApplet)
    }

    React.useEffect(() => {
        const gradient = new Gradient();
        //@ts-ignore
        gradient.initGradient("#gradient-canvas");
    }, [])

    return (
        <div className="h-screen overflow-hidden bg-portfolio-300 backg font-pixel">
            <canvas id="gradient-canvas" />
            {window.location.hostname !== "localhost" && <Boot />}
            <Shortcut className="top-3 left-3" applet={applets.at(-1)!} onOpenWindow={onOpenWindow} onDrag={onDrag} />
            <Shortcut className="top-24 left-3" applet={applets.at(-2)!} onOpenWindow={onOpenWindow} onDrag={onDrag} />
            {/* <Shortcut className="top-44 left-3" applet={applets.at(-3)!} onOpenWindow={onOpenWindow} onDrag={onDrag} /> */}
            <StartBar onOpenWindow={onOpenWindow} onWindowMinimised={onWindowMinimised} openApplets={openApplets} />
            {openApplets.map((applet) => {
                if (applet.minimised) return null
                return (
                    <Window
                        key={applet.id}
                        applet={applet}
                        onResize={onResize}
                        onDrag={onDrag}
                        onMaximise={toggleMaximiseWindow}
                        onClose={onCloseWindow}
                        onMinimise={onWindowMinimised}
                        />
                )
            })}
        </div>
    )

}