import React from "react"
// @ts-ignore
import wheelVideo from "../../assets/videos/wheel.mp4"

export const OvatoWheel = () => {
    return (
        <div className="@container max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Ovato Capabilities Wheel</h1>
            <div className="flex gap-6 flex-col @3xl:flex-row">
                <p>To promote Ovato's services and provide information about its capabilities, I was tasked with developing an interactive experience that would give potential customers a unique way to gain information about services the company could provide. The Ovato wheel turned an ordinary online form into an exciting and fun augmented experience. The camera looked for the marker and displayed a wheel with the four primary services of the company. After making their selection, the user could enter their email and receive information regarding the services they had chosen. I independently developed the project using Three.js and AR.js. This promotion appeared at events held by the company. The interactive experience was made possible through an A6 sized card that contained information on how to use the web app, a QR code that led the user to the web app and a marker for the app to render its content on.</p>
                <video controls className="h-[680px] max-w-[390px] block border-2 border-black bg-gray-800" src={wheelVideo} />
            </div>
        </div>
    )
}