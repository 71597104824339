import React from "react"
import Dragger from "./Dragger"
import Resizer from "./Resizer";
import classNames from "classnames";
import { Applet } from "./Desktop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faSquare, faSquareMinus } from "@fortawesome/free-regular-svg-icons";

import 'animate.css'
import wait from "waait";


interface Props {
    applet: Applet
    onResize: (element: HTMLElement, direction: string, e: MouseEvent, applet: Applet) => void
    onDrag: (element: HTMLElement, e: MouseEvent, applet: Applet) => void
    onClose: (applet: Applet) => void
    onMinimise: (applet: Applet) => void
    onMaximise: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, element: HTMLElement, applet: Applet) => void
}

export const Window = ({ applet, onResize, onDrag, onClose, onMinimise, onMaximise }: Props) => {
    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (!ref.current) return
        const windowRef = ref.current
        if (applet.fullscreen) {
            windowRef.style.top = "0";
            windowRef.style.left = "0";
            windowRef.style.transform = 'none';
            windowRef.style.width = '100%';
            windowRef.style.height = 'calc(100% - 32px)';

        } else {
            windowRef.style.height = applet.floatingPosition.height
            windowRef.style.width = applet.floatingPosition.width
            windowRef.style.left = applet.floatingPosition.left
            windowRef.style.top = applet.floatingPosition.top
        }
        ref.current?.focus()
    }, [applet])


    const closeWindow = async () => {
        ref.current?.classList.add("animate__fadeOut")
        await wait(260)
        onClose(applet)
    }
    const minimiseWindow = async () => {
        ref.current?.classList.add("animate__fadeOutDown")
        await wait(260)
        onMinimise(applet)
    }

    return (
        <div
            key={applet.id}
            ref={ref}
            id={`window-${applet.id}`}
            tabIndex={0}
            className={
                classNames(
                    "group absolute top-2 left-2 min-w-[250px] min-h-[100px] z-10 border-2 border-black bg-white select-none outline-none rounded-md animate__animated fast animate__fadeIn",
                    "focus:z-20 focus-within:z-20",
                    { "shadow-brutal": !applet.fullscreen, "hidden": applet.minimised }
                )
            }
        >
            <Resizer onResize={(direction, e) => onResize(ref.current!, direction, e, applet)} />
            <Dragger fullWidth={true} onDrag={(e) => onDrag(ref.current!, e, applet)}>
                <div className="flex justify-between px-1 py-2 border-b-2 border-black bg-slate-600 group-focus:bg-portfolio-500 group-focus-within:bg-portfolio-500 text-white">
                    <h2 className="select-none">{applet.title}</h2>
                    <div className="flex gap-1">
                        <button aria-label={`Minimise ${applet.title} window`} className="rounded-md flex items-end px-1 border-2 border-black bg-white text-black hover:bg-slate-200 active:bg-portfolio-200" onClick={minimiseWindow}>
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <button aria-label={`Maximise ${applet.title} window`} className="rounded-md flex items-center px-1 border-2 border-black bg-white text-black hover:bg-slate-200 active:bg-portfolio-200" onClick={(e) => {
                            ref.current?.focus()
                            onMaximise(e, ref.current!, applet)
                        }}>
                            {applet.fullscreen
                                ? <FontAwesomeIcon icon={faSquareMinus} />
                                : <FontAwesomeIcon icon={faSquare} />
                            }

                        </button>
                        <button aria-label={`Close ${applet.title} window`} className="rounded-md flex items-center px-1 border-2 border-black bg-white text-black hover:bg-slate-200 active:bg-portfolio-200" onClick={closeWindow}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                </div>
            </Dragger>
            <div className="h-[calc(100%-42px)] overflow-auto">
                {applet.content({})}
            </div>
        </div >
    )
}