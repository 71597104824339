import React from 'react'
import { skills } from './../Welcome'
import { ExperienceItem } from './ExperienceItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons'

export const AboutMe = () => {
  return (
    <div className="@container max-w-[1280px] text-xl @lg:px-4 mx-auto">
      <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">About Me</h1>
      <div className='py-8 px-3 @3xl:px-6 flex flex-col gap-6 @3xl:flex-row'>
        <div>
          <h3 className="text-lg @3xl:text-2xl font-bold mb-4">Experience</h3>
          <ExperienceItem title="Hello Again, Senior Software Engineer" dateRange='May 2024 - Sep 2024 ' description={[
            'Developed an onboarding flow for a clients application that guided user\'s through the process of building their profile',
            'Added animations and scroll effects to a clients website through the use of GSAP',
            'Helped develop a dashboard for a client that allowed their merchant users to access invoices and reports as well as receive metrics about their in app transactions'
          ]} />
          <ExperienceItem title="T-Shirt Ventures, Fullstack developer" dateRange='Sep 2021 - Nov 2023 ' description={[
            'Worked collaboratively to develop a two-sided platform for connecting NDIS participants with service providers as a part of their plan. This platform contained an admin dashboard for an internal operations team to use',
            "Worked collaboratively to expand and maintain an existing plan management platform that allowed participants to monitor the spending of their NDIS plan as well as budget for it's future. This platform also contained an admin platform to assist internal plan managers",
            "Developed a complete understanding of both of these platforms, enabling me to build new features, improve upon existing features, and operate as a tech responder if incidents were to arise",
            "Contributed toward product planning and design throughout all stages of a feature's development"
          ]} />
          <ExperienceItem title="Ovato Innovation, Fullstack developer" dateRange='Feb 2019 - Aug 2021 ' description={[
            'Research and development of the 3D capabilities of the web with the use of WebGL and Three.js',
            'The development of a geo-spacial, data analysis platform that allowed users to target locations of value when distributing printed materials to their audience',
            'The development of multiple virtual (VR) and augmented (AR) reality apps that run directly from the browser',
          ]} />
          <ExperienceItem title="Holler, Frontend developer" dateRange='Oct 2017 - Feb 2019' description={[
            'Research and development of the 3D capabilities of the web with the use of WebGL and Three.js',
            'I independently lead and developed a single page react app for the customers of a client to check loyalty points and appointment results, as well as a Google Home action for checking local cinema\'s and their available movies and session times',
          ]} />
          <ExperienceItem title="GorillaStack, Fullstack developer" dateRange='Jan 2017 - Aug 2017' description="GorillaStack is a small web development start up that provides cloud management infrastructure and solutions for Amazon Web Services. My role was to produce front and back end solutions for the second iteration of the GorillaStack application. I worked both collaboratively and independently" />
        </div>

        <div className="min-w-[200px]">
          <div>
            <h3 className="text-2xl font-bold mb-2">Profile</h3>
            <div className='flex gap-2'>
              <FontAwesomeIcon icon={faUser} className='text-purple-200 fa-add-outline' />
              <p>Will Dunlop</p>
            </div>
            <div className='flex gap-2'>
              <FontAwesomeIcon icon={faLocationDot} className='text-purple-200 fa-add-outline' />
              <p>Sydney, NSW</p>
            </div>
            <div className='flex gap-2'>
              <FontAwesomeIcon icon={faGlobe} className='text-purple-200 fa-add-outline' />
              <p>https://willdunlop.com</p>
            </div>
            {/* <div className='flex gap-2'>
                <FontAwesomeIcon icon={faPhone} className='text-purple-200 fa-add-outline' />
                <p>(+61) 0432 724 454</p>
              </div>
              <div className='flex gap-2'>
                <FontAwesomeIcon icon={faEnvelope} className='text-purple-200 fa-add-outline' />
                <p className="text-xs">william.dunlop.1993@gmail.com</p>
              </div> */}
          </div>
          <div className="mt-6">
            <h3 className="text-2xl font-bold mb-2">Skills</h3>
            <div className="flex flex-wrap gap-2 text-xs">
              {skills.map((skill) => (
                <span key={skill} className="border-2 border-black bg-purple-200 font-semibold rounded-full p-2">{skill}</span>
              ))}

            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-2xl font-bold mb-2">Education</h3>
            <div className="mb-2">
              <h4 className="font-semibold">Coder Factory Academy</h4>
              <p className='text-xs italic'>Aug 2016 - Jan 2017</p>
              <p className='text-sm'>Diploma of Software Development</p>

            </div>
            <div className="mb-2">
              <h4 className="font-semibold">St George TAFE</h4>
              <p className='text-xs italic'>Jan 2016 - Jul 2016</p>
              <p className='text-sm'>Certificate IV in Information Technology Support</p>

            </div>
            <div>
              <h4 className="font-semibold">SAE Qantm College</h4>
              <p className='text-xs italic'>Feb 2012 - Dec 2014</p>
              <p className='text-sm'>Bachelor of Interactive Entertainment with a major in Animation</p>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}