import React from "react"
import { UAParser } from "ua-parser-js"
import { DisplayImage } from "../DisplayImage";
import demmo01 from "../../assets/images/demmo/demmo-01.png"
import demmo02 from "../../assets/images/demmo/demmo-02.png"
import demmo03 from "../../assets/images/demmo/demmo-03.png"
import { getScreenSize, isMobileDevice, ScreenSize } from "../../utils/getScreenSize";

interface DeMMOProps {
    skipInfo?: boolean;
}

const demmoUrl = "https://demmo.willdunlop.com"

export const DeMMO = ({ skipInfo = false }: DeMMOProps) => {
    const [isPlaying, setIsPlaying] = React.useState(skipInfo)
    const screenSize = getScreenSize()
    const canPlay = (screenSize !== ScreenSize.MOBILE && screenSize !== ScreenSize.TABLET) && !isMobileDevice()


    if (isPlaying) {
        return (
            <iframe
                id="inlineDeMMO"
                title="DeMMO"
                width="100%"
                height="100%"
                src={demmoUrl}>
            </iframe>
        )
    }

    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto pb-6">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">DeMMO</h1>
            <p className="mt-4">Back in 2003, when I was just ten years old, a friend of mine introduced me to a browser based, massively multiplayer online (MMO) game called Runescape. From that moment in time, it became a dream of mine to create my own online world where people can hang out and interact with one another. DeMMO is a small little side project that aims to do exactly that</p>
            <DisplayImage image={demmo01} caption="Three players are hanging out in the (very small) world of DeMMO" />
            <p className="mt-4">It is important to mention that this "game" is not nearly as exciting as Runescape, in fact it is little more than a 3D chatroom at this point. Despite it's simplicity, DeMMO is a lot of fun to work on, and something I take quite a bit of pride in.</p>
            <p className="mt-4">DeMMO is controlled entirely by it's server which uses socket.io to keep every connected client up to date in real time. This includes player positions, player pathing, player conversations, environmental object locations, grid management and more. This allows users to control a character with point-and-click controls, and walk him around a shared virtual space with other users. Those users can also communicate with one another using the chat feature</p>
            <DisplayImage image={demmo03} caption="A player is on the move. Here we can see the player's avatar walking along a path which was calculated on the server. The path ends at the pink square" />
            <p className="mt-4">The client receives all the realtime data from the server and uses it to construct the game world with Three.js. It also utilises react for rendering the UI elements such as the chat window</p>
            {/* <DisplayImage image={demmo02} caption="The landing page for DeMMO, clearly a work in progress..." /> */}
            {
                canPlay ? (
                    <>
                        <p className="mt-4">Feel free to check out DeMMO by clicking play now! Remember DeMMO is online, so invite a friend to join in with you</p>
                        <div className="w-full text-center">
                            <button onClick={() => setIsPlaying(true)} className="px-4 py-2 mt-6 bg-gray-200 border-2 border-black hover:bg-gray-400 active:bg-gray-600 active:text-white">Play Now</button>
                        </div>
                    </>
                ) : (
                    <p className="mt-4">DeMMO is live now for you to try out. If you would like to test it out, revisit this page on a PC or laptop.</p>
                )
            }
        </div>
    )
}

