import React from 'react'
import procho01 from "../../assets/images/procho/procho-01.png"
import procho02 from "../../assets/images/procho/procho-02.png"
import { DisplayImage } from '../DisplayImage'

export const ProCho = () => {

    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">Provider Choice</h1>
            <p className="mt-4">Provider Choice is a platform for NDIS plan management and is one of the products offered by T-shirt Ventures. The goal is to assist participants in a process that can often be complex and difficult to understand.</p>
            <p className="mt-4">One of the features I contributed to this platform was the Budget tool, which enabled users to manage their spending. They could search through a list of services, select the frequency and rate of the service, and then add it to their budget plan. Once they had added all their services, they would gain insight into their spending and make adjustments where required.</p>
            <DisplayImage image={procho01} caption="A participant views a summary of their plan's progress" />
            <p className="mt-4">I also integrated the platform with the HubSpot APIs so that deal cards could be generated whenever a new participant joined the platform. This informed our onboarding team of the new signup, and they could then contact the user and begin the onboarding proccess.</p>
            <DisplayImage image={procho02} caption="The Provider Choice dashboard" />
        </div>
    )
}





