export enum ScreenSize {
	MOBILE = "MOBILE",
	TABLET = "TABLET",
	LAPTOP = "LAPTOP",
	DESKTOP = "DESKTOP",
	TV = "TV",
}

const screenSizes = {
	[ScreenSize.MOBILE]: 768,
	[ScreenSize.TABLET]: 1024,
	[ScreenSize.LAPTOP]: 1280,
	[ScreenSize.DESKTOP]: 1920,
	[ScreenSize.TV]: Infinity,
};

export const isMobileDevice = () => {
    const userAgent = window.navigator.userAgent;
    return /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(userAgent);
}


export const getScreenSize = () => {
    const screenSize = Object.entries(screenSizes).find(([key, value]) => {
        return window.innerWidth < value
    })

    if (screenSize) return screenSize[0]
};

