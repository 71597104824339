import { faHourglassEnd, faHourglassHalf, faHourglassStart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export const Loader = () => {
    const [state, setState] = React.useState(0)

    React.useEffect(() => {
        let count = 0
       const interval =  setInterval(() => {
        count++
        setState(count % 4)
       }, 750)

       return () => clearInterval(interval)
    }, [])
    switch (state) {
        case 1: return <FontAwesomeIcon className="text-white" size="2x" icon={faHourglassStart} />
        case 2: return <FontAwesomeIcon className="text-white" size="2x" icon={faHourglassHalf} />
        case 3: return <FontAwesomeIcon className="text-white" size="2x" icon={faHourglassEnd} />
        case 0: return <FontAwesomeIcon className="text-white spin" size="2x" icon={faHourglassEnd} />
        default: return <FontAwesomeIcon size="2x" icon={faHourglassStart} />
    }
}