import { Applet, FloatingPosition } from "../components/Desktop";
import { Atlas } from "../components/applets/Atlas";

import hhIcon from "../assets/icons/hh.png"
import prochoIcon from "../assets/icons/procho.png"
import ovatoIcon from "../assets/icons/ovato.png"
import magazineIcon from "../assets/icons/8thWall-logo.jpg"
import blackmoresIcon from "../assets/icons/blackmores.png"
import bloomsIcon from "../assets/icons/blooms.png"
import { MagazineAR } from "../components/applets/MagazineAR";
import { Blackmores } from "../components/applets/Blackmores";
import { OvatoWheel } from "../components/applets/OvatoWheel";
import { Blooms } from "../components/applets/Blooms";
import { ProCho } from "../components/applets/ProCho";
import { HeyHubble } from "../components/applets/HeyHubble";
import { ScreenSize, getScreenSize } from "../utils/getScreenSize";
import { DeMMO } from "../components/applets/Demmo";
import { BackroomsZero } from "../components/applets/BackroomsZero";

const initialFloatingPosition: FloatingPosition = {
    width: "160px",
    height: "160px",
    top: "10",
    left: "10",
}

function initApplet(applet: Applet, index: number): Applet {
    const screen = getScreenSize()
    switch (screen) {
        case ScreenSize.MOBILE: 
            return {
                ...applet,
                fullscreen: false,
                floatingPosition: {
                    ...initialFloatingPosition,
                    width: "95%",
                    height: "90%",
                    top: `${0 + index * 8}px`,
                    left: `${0 + index * 4}px`
                }
            }
        case ScreenSize.TABLET:
        case ScreenSize.LAPTOP:
                return {
                    ...applet,
                    floatingPosition: {
                        ...initialFloatingPosition,
                        width: "80%",
                        height: "70%",
                        top: `${0 + (index+1) * 32}px`,
                        left: `${0 + (index+2.5) * 16}px`
    
                    }
                }
        case ScreenSize.DESKTOP:
            return {
                ...applet,
                floatingPosition: {
                    ...initialFloatingPosition,
                    width: "1080px",
                    height: "720px",
                    top: `${0 + (index+1) * 32}px`,
                    left: `${0 + (index+2.5) * 16}px`

                }
            }
        case ScreenSize.TV:
            return {
                ...applet,
                floatingPosition: {
                    ...initialFloatingPosition,
                    width: "1080px",
                    height: "720px",
                    top: `${0 + (index+1) * 64}px`,
                    left: `${0 + (index+2.5) * 32}px`

                }
            }
        default: return applet
    }

}


export const initialApplets: Applet[] = [

    /** Professional */
    {
        id: "HEYH",
        title: "HeyHubble",
        icon: hhIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "5%",
            left: "2.5%",
        },

        content: HeyHubble
    },
    {
        id: "PROCHO",
        title: "Provider Choice",
        icon: prochoIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "10%",
            left: "5%",
        },

        content: ProCho
    },

    {
        id: "ATLAS",
        title: "Atlas",
        icon: ovatoIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "15%",
            left: "7.5%",
        },

        content: Atlas

    },

    {
        id: "MAGAZINE_AR",
        title: "Magazine AR",
        icon: magazineIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "20%",
            left: "10%",
        },
        content: MagazineAR

    },

    {
        id: "BLACKMORES",
        title: "Blackmores - Mindful Ocean VR",
        icon: blackmoresIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "25%",
            left: "12.5%",
        },
        content: Blackmores

    },

    {
        id: "CAPABILITIES_WHEEL",
        title: "Ovato Capabilities Promotion",
        icon: ovatoIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "30%",
            left: "15%",
        },
        content: OvatoWheel

    },

    
    {
        id: "BLOOMS",
        title: "Blooms Website",
        icon: bloomsIcon,
        minimised: false,
        fullscreen: false,
        floatingPosition: {
            ...initialFloatingPosition,
            width: "1080px",
            height: "800px",
            top: "35%",
            left: "17.5%",
        },
        content: Blooms
        
    },
    // {
    //     id: "DEMMO",
    //     title: "DeMMO",
    //     icon: bloomsIcon,
    //     minimised: false,
    //     fullscreen: false,
    //     floatingPosition: {
    //         ...initialFloatingPosition,
    //         width: "1080px",
    //         height: "800px",
    //         top: "35%",
    //         left: "17.5%",
    //     },
    //     content: () => DeMMO({ skipInfo: false })
        
    // },
    // {
    //     id: "BACKROOMS_ZERO",
    //     title: "Backrooms 0",
    //     icon: bloomsIcon,
    //     minimised: false,
    //     fullscreen: false,
    //     floatingPosition: {
    //         ...initialFloatingPosition,
    //         width: "1080px",
    //         height: "800px",
    //         top: "35%",
    //         left: "17.5%",
    //     },
    //     content: () => BackroomsZero({ skipInfo: false })
        
    // },
    
    // {
    //     id: "ABOUT_ME",
    //     title: "About me",
    //     icon: aboutIcon,
    //     minimised: false,
    //     fullscreen: false,
    //     floatingPosition: {
    //         ...initialFloatingPosition,
    //         width: "1080px",
    //         height: "800px",
    //         top: "35%",
    //         left: "17.5%",
    //     },
    //     content: Blooms
        
    // },
    

    /** Personal */
    //{
    //     id: "PORTFOLIO",
    //     title: "this.portfolio",
    //     menuType: ToolchestMenuItems.PERSONAL,
    //     icon: "/assets/icons/blackmores.png",
    // minimised: false,
    // fullscreen: false,
    // floatingPosition: initialFloatingPosition,
    //     style: {
    //         desktop: {
    //             size: { width: '50%', height: '80%' },
    //             position: { top: '150px', left: '420px' },
    //         },
    //         mobile: {
    //             size: { width: '100%', height: `calc(100% - ${TABLET_BAR_HEIGHT})` },
    //             position: { top: '0', left: '0' },
    //         }
    //     },
    //     content: (props:any) => <Blackmores />

    // },

    // ALPHA_CORE: {
    //     id: "ALPHA_CORE",
    //     title: "Alpha Core MMO Server",
    //     menuType: ToolchestMenuItems.PERSONAL,
    //     icon: "/assets/icons/blackmores.png",
    // minimised: false,
    // fullscreen: false,
    // floatingPosition: initialFloatingPosition,
    //     style: {
    //         desktop: {
    //             size: { width: '50%', height: '80%' },
    //             position: { top: '150px', left: '420px' },
    //         },
    //         mobile: {
    //             size: { width: '100%', height: `calc(100% - ${TABLET_BAR_HEIGHT})` },
    //             position: { top: '0', left: '0' },
    //         }
    //     },
    //     content: (props:any) => <Blackmores />

    // },

    // DEMMO: {
    //     id: "DEMMO",
    //     title: "DeMMO",
    //     menuType: ToolchestMenuItems.PERSONAL,
    //     icon: "/assets/icons/blackmores.png",
    // minimised: false,
    // fullscreen: false,
    // floatingPosition: initialFloatingPosition,
    //     style: {
    //         desktop: {
    //             size: { width: '50%', height: '80%' },
    //             position: { top: '150px', left: '420px' },
    //         },
    //         mobile: {
    //             size: { width: '100%', height: `calc(100% - ${TABLET_BAR_HEIGHT})` },
    //             position: { top: '0', left: '0' },
    //         }
    //     },
    //     content: (props:any) => <Blackmores />

    // },

    // BACKROOMS_ZERO: {}
]

export const professional = initialApplets.map(initApplet)

