import React from "react"

import hh01 from "../../assets/images/hh/HH-01.png"
import hh02 from "../../assets/images/hh/HH-02.png"
import { DisplayImage } from "../DisplayImage"


export const HeyHubble = () => {
    return (
        <div className="max-w-[1080px] text-xl px-4 mx-auto">
            <h1 className="text-2xl text-center font-semibold border-b border-black mb-2">HeyHubble</h1>
            <p className="mt-4">HeyHubble is a two-sided marketplace that operates within the NDIS space. It connects participants with providers who offer the services that they need. While working at T-shirt Ventures, I spent a lot of my time working on this platform.</p>
            <p className="mt-4">One of the first features I helped to build was the provider shopfront, a public facing profile page that allowed the providers on the platform to advertise who they are and what they offer. I aslo implemented an overhaul of the service request form, the primary utility on the platform that enabled our participant users to inform providers of the services they needed.</p>
            <DisplayImage image={hh01} caption="A participant's request for exercise physiology has two offers pending their review" />
            <p className="mt-4">The PlanHelper tool was another addition that my team contributed to the platform. It recommended services based on a participant's specific needs, such as their age, disability, and their areas of interests. The recommendations were displayed in an intuitive and pleasing manner.</p>
            <p className="mt-4">I also made many improvements and modifications to the admin dashboard that assisted our operations team with finding matches.</p>
            <DisplayImage image={hh02} caption="The PlanHelper page with suggested services for the user" />
        </div>
    )

}


