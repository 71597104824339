import welcomeIcon from "../assets/icons/welcome.png";
import aboutIcon from "../assets/icons/about.png";

import { Applet, FloatingPosition } from "../components/Desktop";
import { Welcome } from "../components/applets/Welcome";
import { ScreenSize, getScreenSize } from "../utils/getScreenSize";
import { AboutMe } from "../components/applets/AboutMe";
import { WillShell } from "../components/applets/programs/WillShell";

const initialFloatingPosition: FloatingPosition = {
	width: "160px",
	height: "160px",
	top: "10",
	left: "10",
};

function initApplet(applet: Applet, index: number): Applet {
	const screen = getScreenSize();
	switch (screen) {
		case ScreenSize.MOBILE:
			return {
				...applet,
				fullscreen: false,
				floatingPosition: {
					...initialFloatingPosition,
					width: "95%",
					height: "90%",
					top: `calc(2.5% + ${index * 8}px)`,
					left: `calc(2.5% + ${index * 4})`,
				},
			};
		case ScreenSize.TABLET:
			return {
				...applet,
				floatingPosition: {
					...initialFloatingPosition,
					width: "90%",
					height: "90%",
					top: `calc(2.5% + ${index * 32}px)`,
					left: `calc(5% + ${index * 16})`,
				},
			};
		case ScreenSize.LAPTOP:
		case ScreenSize.DESKTOP:
		case ScreenSize.TV:
			const height = applet.id === "WELCOME" ? 600 : 1000;
			return {
				...applet,
				floatingPosition: {
					...initialFloatingPosition,
					width: "1080px",
					height: height + "px",
					top: `calc(50% - ${height / 2}px - ${index * 64}px)`,
					left: `calc(50% - 540px - ${index * 32}px)`,
				},
			};
		default:
			return applet;
	}
}

const initialApplets = [
	{
		id: "WILL_SHELL",
		title: "WiSH",
		icon: aboutIcon,
		minimised: false,
		fullscreen: false,
		floatingPosition: {
			...initialFloatingPosition,
			width: "1080px",
			height: "800px",
			top: "35%",
			left: "17.5%",
		},
		content: WillShell,
	},
];

export const programs = initialApplets.map(initApplet);
